<template>
  <div class="about" >
    <div class="d-flex flex-column min-vh-100">  
      <ul>
        <li>
          <div class="mt-3" >
            <Jointable v-if="JSON.stringify(this.objs)!=='{}'"  :items="objs" />
          </div>
        </li>
      </ul>          
    <div class="wrapper flex-grow-1"></div>      
    </div>
  </div>
</template>
<script>
import { joinService } from '../../_services'
import * as moment from "moment/moment";
import Jointable from './panel/Jointable'


const queryData = params => {
  return joinService.menber(params.id) 
};


export default {
  name: 'Joinlist',
  components: {
    Jointable

  }, 
  props: {
    msg: String
  },
  data() {
    return {
      showTop: false,       
      windowsize: {
            width: 0,
            height: 0
        },        
      classtheme:null,
      objs: {},      
      uId:'',
    };
  },  
  mounted(){
        //console.log(this.$route.query.userId);
    this.uId = this.$route.params.id
    this.fetch({id:this.uId});
  },
  methods: {
    query(rs){
      this.classtheme = rs
    },
    getwinsize(val){
      this.windowsize.width = val.width
      this.windowsize.height = val.height
    },         
    fetch(params = {}) {
      queryData({
        ...params,
      }).then(({ data }) => {
          this.objs = data.data;
      });
    },
    fdateformat(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    },
    showmessage(){
          this.showTop = true;
          setTimeout(() => {   
              this.showTop = false;
              clearInterval();
          }, 4000);       
    }
  },    
}
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.sidenav {
  width: 230px;
  position: fixed;
  z-index: 1;
  top: 130px;
  right: 0;
  overflow-x: hidden;
  padding: 0;
}
.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  color: #2196F3;
  display: block;
}

.sidenav a:hover {
  color: #064579;
}
.applyfooter {
   position: fixed;
   left: 0;
   bottom: 0;
   width: 100%;
   height: 60px;
   background-color: #f0f8f8;
   color: #f0f8f8;
   text-align: center;
   
}


</style>

