var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-spin',{attrs:{"size":"large","spinning":_vm.spinning,"tip":"產生列印資料..."}},[_c('b-card',{staticClass:"card-3",attrs:{"header":"活動報名人員清單","img-src":_vm.items.url !== '' ? _vm.items.url : _vm.catImg('event_1080x540.png'),"header-border-variant":"light","header-bg-variant":"light","align":"center"},scopedSlots:_vm._u([{key:"footer",fn:function(){return undefined},proxy:true}])},[_c('div',{staticStyle:{"max-width":"980px","text-align":"right"}},[_c('b-button',{attrs:{"type":"submit","squared":"","variant":"link"},on:{"click":_vm.exportToPDF}},[_vm._v(" 輸出報表 "),_c('b-icon',{attrs:{"icon":"printer","aria-hidden":"true"}})],1)],1),_c('b-card-body',{ref:"document",staticClass:"overflow-auto",staticStyle:{"max-width":"980px","padding":"15px 0px 5px 0px"}},[_c('b-card-text',[_c('div',{staticClass:"text-left",staticStyle:{"padding":"5px 5px 5px 25px"}},[_c('div',{staticClass:"classempy",attrs:{"id":"word1head"}},[_c('div',{staticStyle:{"margin-bottom":"-0.2rem"}},[_c('strong',[_c('span',{staticStyle:{"font-size":"18px"},domProps:{"textContent":_vm._s(_vm.items.actitle)}})])]),_c('b-icon',{attrs:{"icon":"clock","variant":"success"}}),_vm._v(" 活動時間 : "),_c('span',{staticStyle:{"font-size":"14px","background-color":"#ebf1f6"},domProps:{"textContent":_vm._s(
                  _vm.fdateformat(_vm.items.sdate) +
                  ' ~ ' +
                  _vm.fdateformat(_vm.items.edate) +
                  ' (人員報名資料如下) 目前人次 : ' +
                  _vm.items.acts_detrimal.length
                )}})],1),_c('div',{staticStyle:{"max-height":"350px","overflow-x":"hidden","overflow-y":"auto"},attrs:{"id":"style-3"}},_vm._l((_vm.items.acts_detrimal),function(element,index){return _c('div',{key:element.id,staticClass:"list-group-item",staticStyle:{"padding":"2px","margin":"2px","max-height":"160px"},attrs:{"id":'word' + element.id}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[(
                        index > 0 &&
                        _vm.items.acts_detrimal[index - 1].sdate !==
                          _vm.items.acts_detrimal[index].sdate
                      )?_c('hr',{staticClass:"hr-text",attrs:{"data-content":_vm.formatDate(element.sdate)}}):_vm._e(),_c('span',{staticStyle:{"padding":"0px","margin":"1px","font-size":"14px","margin-left":"10px"},domProps:{"textContent":_vm._s(
                        '角色 : ' +
                        _vm.getRole(element.roles) +
                        ' | 課程時間 :' +
                        element.sdate +
                        '~' +
                        element.edate
                      )}}),_vm._l((element.byrsoptions),function(item1){return _c('span',{key:item1.id},[(item1.oid==1 || item1.oid==2)?_c('span',{staticStyle:{"font-size":"14px"},domProps:{"textContent":_vm._s(_vm.getdata(item1.oid , item1))}}):_vm._e()])})],2)])])}),0)])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }